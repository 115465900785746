import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesGamemodesTribePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tribe Towers</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_tribe.png"
            alt="Tribe Towers"
          />
        </div>
        <div className="page-details">
          <h1>Tribe Towers</h1>
          <h2>Guide for the Tribe Towers in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Tribe Towers" />
        <p>
          Another staple of gacha games - the tower mode. You start at the
          bottom of the tower and after defeating the enemies occupying floor
          number one, you can climb to the next floor and rinse and repeat.
        </p>
        <p>
          To unlock this mode, you have to clear stage 3-3 and you can obtain a
          lot of resources here, including character shards and dust which you
          will always have too little (and you use it level up characters).
        </p>
        <SectionHeader title="5 different towers" />
        <StaticImage
          src="../../../images/nikke/generic/guide_tribe.jpg"
          alt="Tribe Tower"
        />
        <p>
          In addition to the Tribe Tower, where you can use any character you
          like, there are also 4 additional towers - each dedicated to one of
          the manufacturers available in the game where you can only use
          characters from that manufacturer. Which is troublesome for the
          Pilgrim Tower.
        </p>
        <p>
          Not only the Pilgrim faction is limited to SSR rarity characters, but
          also on the game's release there's only one Burst I and one Burst II
          character - and if you don't have them, you will have a lot of issues
          to progress in that tower since you won't be able to use the skills of
          Burst III characters.
        </p>
        <p>
          While the Tribe Tower is open all the time, what manufacturer tower is
          open changes depending on the day. Furthermore, for the manufacturer
          towers, you can only pass three floors per day.
        </p>
        <ul>
          <li>Monday - all</li>
          <li>Tuesday - Elysion</li>
          <li>Wednesday - Missilis and Pilgrim</li>
          <li>Thursday - Tetra</li>
          <li>Friday - Elysion</li>
          <li>Saturday - Missilis</li>
          <li>Sunday - Tetra</li>
        </ul>
        <SectionHeader title="Floors" />
        <StaticImage
          src="../../../images/nikke/generic/guide_tower_1.jpg"
          alt="Tribe Tower"
        />
        <p>
          Each floor is unique and compared to Simulation room, where only
          Destroy type battles are present, in the Tower you will also encounter
          Base, Defense and Boss battle types.
        </p>
        <p>
          Similar to Campaign, before you enter a floor, you will obtain all the
          essential information that will help you in beating it.
        </p>
        <SectionHeader title="Rewards" />
        <p>
          Passing a floor will give you credits, materials, Core Dust and
          Manufacturer Molds (only for manufacturer towers). Every 5 and 10
          floors, the amount of rewards increases.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/tower_mold.jpg"
          alt="Tribe Tower"
        />
        <p>
          The Manufacturer Molds are especially interesting. When you gather 50,
          you will be able to consume them and obtain a full character belonging
          to that manufacturer. The rarity of the character is up to rng though:
        </p>
        <ul>
          <li>SSR - 50%</li>
          <li>SR - 30%</li>
          <li>R - 20%</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesTribePage;

export const Head: React.FC = () => (
  <Seo
    title="Tribe Towers | NIKKE | Prydwen Institute"
    description="Guide for the Tribe Towers in Goddess of Victory: NIKKE."
  />
);
